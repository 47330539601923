<template>
    <page-title
        title="応援カメラマン登録"
        icon="bi-people-fill"
    >
        <router-link :to="{name: 'PartnerList'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> 応援カメラマン一覧へ
        </router-link>
    </page-title>

    <form>
        <section class="section">
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>氏名</label>
                    <input type="text" class="form-control" required>
                </div>
                <div class="form-group col-md-3">
                    <label>略称</label>
                    <input type="text" class="form-control" required>
                </div>
                <div class="form-group col-md-4">
                    <label>ランク</label>
                    <select class="form-select" required>
                        <option>-- 選択 --</option>
                        <option>プレミアム</option>
                        <option>準レギュラー</option>
                        <option>育成</option>
                        <option>人気スポット</option>
                        <option>スポット</option>
                        <option>戦力外</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label>名刺</label>
                    <div class="form-control">
                        <input class="form-check-input ms-1 me-1" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            有
                        </label>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>誓約書</label>
                    <div class="form-control">
                        <input class="form-check-input ms-1 me-1" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            済
                        </label>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="row mb-4">
                <div class="form-group col-md-4">
                    <label>郵便番号</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label>都道府県</label>
                    <select class="form-select" required>
                        <option>-- 選択 --</option>
                        <template v-for="pref in prefs" :key="pref">
                            <option>{{ pref.label }}</option>
                        </template>
                    </select>
                </div>
                <div class="form-group col-md-10">
                    <label>住所</label>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-3">
                    <label>電話番号</label>
                    <input type="tel" class="form-control">
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>メールアドレス</label>
                    <input type="email" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label>生まれ年</label>
                    <input type="number" class="form-control">
                </div>
                <div class="form-group col-md-4">
                    <label>車の有無</label>
                    <select class="form-select" v-model="car">
                        <option value="">-- 選択 --</option>
                        <option value="yes">有</option>
                        <option value="no">無</option>
                    </select>
                </div>
                <div class="form-group col-md-4" v-if="car === 'yes'">
                    <label>車種（任意）</label>
                    <input type="input" class="form-control">
                </div>
            </div>
        </section>

        <section class="section">
            <div class="col-md-10">
                <table class="table">
                    <thead class="">
                        <th class=""></th>
                        <th class="text-center">繁忙期入れるか</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>入学式</td>
                            <td>
                                <select class="form-select">
                                    <option>-</option>
                                    <option>○</option>
                                    <option>△</option>
                                    <option>×</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>運動会</td>
                            <td>
                                <select class="form-select">
                                    <option>-</option>
                                    <option>○</option>
                                    <option>△</option>
                                    <option>×</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </section>

        <section class="section">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>エルに入る経緯</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label>雰囲気・愛想</label>
                    <select class="form-select">
                        <option>○</option>
                        <option>△</option>
                        <option>×</option>
                    </select>
                </div>
            </div>
        </section>
        <section class="section">
            <table class="table col-md-12">
                <thead>
                    <tr>
                        <th class="col-md-7"></th>
                        <th class="text-center">撮影技量</th>
                        <th class="text-center">備考</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>集合写真</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>ストロボセット(照明・個人)</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>アルバム撮影</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>声かける系 (遠足・クリスマス会等)</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>式典系</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>動きもの・発表会系（運動会・生活発表会等）</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>クリスマス会プレゼント渡し</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>賞状渡し</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>ピアノ発表会</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>定期演奏会</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                    <tr>
                        <td>スポーツ大会</td>
                        <td>
                            <select class="form-select">
                                <option>-</option>
                                <option>○</option>
                                <option>△</option>
                                <option>×</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control">
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
        </section>

        <section class="section">
            <div class="row mb-3">
                <div class="form-group col-md-10 mb-3">
                    <label>特殊機材（ひな壇、ストロボセット等）</label>
                    <textarea class="form-control" rows="5"></textarea>
                </div>
                <div class="form-group col-md-10 mb-3">
                    <label>苦手な機材（望遠ない等）</label>
                    <textarea class="form-control" rows="5"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="form-group col-md-10 mb-3">
                    <label>備考（過去の失敗や特性等）</label>
                    <textarea class="form-control" rows="5"></textarea>
                </div>
                <div class="form-group col-md-10 mb-3">
                    <label>ランクアップ課題</label>
                    <textarea class="form-control" rows="5"></textarea>
                </div>
            </div>
        </section>

        <section class="section">
            <table class="table">
                <thead>
                    <tr>
                        <th class="col-md-4">出禁の学校</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="banned_photographers.length">
                        <tr v-for="banned_photographer, index in banned_photographers" :key="banned_photographer">
                            <td>
                                <select class="form-select" v-model="banned_photographer.school">
                                    <option>北部小</option>
                                    <option>東部小</option>
                                    <option>西部小</option>
                                    <option>南部中</option>
                                </select>
                            </td>
                            <td>
                                <button type="button" class="btn btn-outline-danger" @click="removeBannedSchool(index)">削除</button>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot></tfoot>
            </table>
            <div>
                <button type="button" class="btn btn-primary" @click="addBannedSchool">
                    <i class="bi bi-plus"></i>追加
                </button>
            </div>
        </section>

        <section class="section">
            <div class="col-4 form-upload-button bg-light" style="height: 200px">
                <label class="d-flex justify-content-center align-items-center" style="height: 100%;">
                    <input type="file" style="display: none;" multiple="multiple">
                    <h1><i class="bi bi-person-bounding-box"></i></h1>
                </label>
            </div>
        </section>

        <section class="section">
            <button class="btn btn-primary btn-lg">
                <i class="bi bi-plus-lg"></i> 登録
            </button>
        </section>
    </form>

</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import Pref from '@/models/enums/pref';

export default {
    name: 'PartnerAdd',
    components: {
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            prefs: Pref.options(),
            car: '',
            banned_photographers: [],
        }
    },
    mounted() {

    },
    methods: {
        addBannedSchool() {
            this.banned_photographers.push({school: '北部小'})
        },
        removeBannedSchool(index) {
            this.banned_photographers.splice(index, 1);
        },
    }
}
</script>

<style scoped>

</style>
